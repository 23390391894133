<template>
    <div class="offers">

        <Card
            v-if="offers.length"
            addclass="mb-30">
            <div class="offers__filters">
                <div class="offers__filters__row">

                    <FormInput
                        :label="$lng.__('lk_webmaster_offers', 'Offer name')"
                        :placeholder="$lng.__('lk_webmaster_offers', 'Search by offer name')"
                        v-model="filters.byName"
                    ></FormInput>

                    <FormSelectMulti
                        v-if="categories.length"
                        :label="$lng.__('lk_webmaster_offers', 'Category')"
                        :placeholder="$lng.__('lk_webmaster_offers', 'Choose categories')"
                        v-model:selected="filters.byCategories"
                        :borderless=false
                        :multiple=true
                        :nested=false
                        key="filterByCategory"
                        :options=categories>
                    </FormSelectMulti>

                    <FormSelectMulti
                        v-if="optionsGeo.length"
                        :label="$lng.__('lk_webmaster_offers', 'Geo')"
                        :placeholder="$lng.__('lk_webmaster_offers', 'Choose geo')"
                        v-model:selected="filters.byGeo"
                        :borderless=false
                        :multiple=true
                        :nested=false
                        key="filterByGeo"
                        :options=optionsGeo>
                    </FormSelectMulti>
                </div>
                <div class="offers__filters__row">
                    <div class="offers__filters__switches">
                        <FormSwitch
                            :label="$lng.__('lk_webmaster_offers', 'Only top offers')"
                            v-model:checked="filters.onlyTopOffers"
                        ></FormSwitch>

                        <FormSwitch
                            :label="$lng.__('lk_webmaster_offers', 'Only new offers')"
                            v-model:checked="filters.onlyNewOffers"
                        ></FormSwitch>
                    </div>


                    <button
                        v-if="isResetFiltersVisible"
                        @click="resetFilters()"
                        class="wv-btn--green wv-btn--text">
                        {{ $lng.__('lk_webmaster_main', 'Reset') }}
                    </button>
                </div>
            </div>

        </Card>


        <Loading v-if="!offers.length"></Loading>

        <Card v-if="offers.length">
            <div class="offers__head">
                <h3>
                    {{ $lng.__('lk_webmaster_offers', 'All offers') }}
                </h3>
                <ToggleViewMode
                    :mode="viewMode"
                    @switch="setOffersViewMode"
                ></ToggleViewMode>
            </div>
        </Card>

        <OffersBlock
            v-if="offers.length"
            :viewMode="viewMode"
            @sortByName="swithSortByName"
            :sortByName="sortByName"
            :offers="offersLimited"
        ></OffersBlock>

        <div
            v-if="this.offersFiltered.length > this.visibleItems"
            class="offers__footer">
            <div class="offers__footer__label">
                {{ $lng.__('lk_webmaster_main', 'Showing %s of %s items').replace('%s', visibleItems).replace('%s', offersFiltered.length) }}
            </div>
            <button
                @click="showMoreOffers()"
                class="wv-btn--blue wv-btn--text">
                {{ $lng.__('lk_webmaster_main', 'Show more') }}
            </button>
        </div>

        <InView
            v-if="offers.length && this.offersFiltered.length > this.visibleItems"
            @inview="showMoreOffers()"
        ></InView>

    </div>
</template>

<script>
    import OffersBlock from './OffersBlock.vue';
    import ToggleViewMode from './../../../components/ToggleViewMode.vue';

    export default {
        name: 'Offers',
        props: [],
        data() {
            return {
                viewMode: 'table',
                initialFilters: {
                    byName: '',
                    byCategories: [],
                    byGeo: [],
                    onlyTopOffers: false,
                    onlyNewOffers: false,
                },
                sortByTop: true,
                sortByName: 'asc',
                filters: false,
                visibleItems: 8,
            }
        },
        computed: {
            offers() {
                return this.$store.state.webmaster.offers
            },
            offersLimited() {
                return this.offersFiltered.slice(0, this.visibleItems)
            },
            offersSorted() {
                if (this.sortByTop) {
                    // sort by top offers
                    return collect(this.offers).sortBy(offer => {
                        return offer.is_top ? 0 : 1;
                    }).all();
                } else {
                    // sort by name
                    let sortedAsc = this.offers.sort(function(a, b) {
                        return ('' + a.name).localeCompare(b.name);
                    })
                    return this.sortByName === 'asc' ? sortedAsc : sortedAsc.reverse()
                }
            },
            offersFiltered() {
                return this.offersSorted.filter(offer => {
                    let pass = true

                    // фильтр "только топ офферы"
                    if (this.filters.onlyTopOffers) {
                        pass = offer.is_top
                    }

                    // фильтр "только новые офферы"
                    if (pass && this.filters.onlyNewOffers) {
                        pass = offer.is_new
                    }

                    // фильтр по названию потока
                    if (pass && this.filters.byName.length > 0) {
                        let query = this.filters.byName.toLowerCase();
                        pass = offer.name.toLowerCase().indexOf(query) >= 0;
                    }

                    // фильтр по категориям
                    if (pass && this.filters.byCategories.length > 0) {
                        pass = false
                        let categoriesTerms = collect(this.filters.byCategories).flatten().unique().all().join('|')
                        offer.category.forEach(cat => {
                            if (categoriesTerms.indexOf(cat) !== -1) pass = true
                        })
                    }

                    // фильтр по geo
                    if (pass && this.filters.byGeo.length > 0) {
                        pass = false
                        let geoTerms = collect(this.filters.byGeo).flatten().unique().all().join('|')

                        pass = collect(offer.payments).filter(payment => {
                            return geoTerms.indexOf(payment.geo) !== -1
                        }).count() > 0
                    }

                    return pass
                })
            },
            categories() {
                return collect(this.offers)
                    .pluck('category')
                    .flatten()
                    .unique()
                    .map(cat => { return { id: cat, name: cat } })
                    .all()
            },
            optionsGeo() {
                return collect(this.offers)
                    .pluck('payments')
                    .flatten(1)
                    .pluck('geo')
                    .unique()
                    .map(geo => { return { id: geo, name: geo } })
                    .all()
            },
            isResetFiltersVisible() {
                return this.filters.byName.length > 0
                    || this.filters.onlyTopOffers
                    || this.filters.onlyNewOffers
                    || this.filters.byCategories.length > 0
                    || this.filters.byGeo.length > 0
            },
        },
        methods: {
            setOffersViewMode(mode) {
                this.viewMode = mode;
            },
            resetFilters() {
                this.filters = JSON.parse(JSON.stringify(this.initialFilters))
            },
            showMoreOffers() {
                let diff = this.offersFiltered.length - this.visibleItems
                diff > 8 ? this.visibleItems+=8 : this.visibleItems+=diff
            },
            swithSortByName() {
                this.sortByTop = false;
                this.sortByName = this.sortByName === 'asc' ? 'desc' : 'asc';
            }
        },
        beforeMount() {
            this.resetFilters();
            this.$store.dispatch('webmaster/getOffers');
        },
        components: {
            OffersBlock,
            ToggleViewMode,
        }
    };
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .offers{
        width: 100%;
        margin-top: 90px;
        @include break(md) {
            margin-top: 0;
        }
        &__head{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        &__filters{
            &__row{
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                @include break(md) {
                    flex-flow: row wrap;
                    align-items: center;
                }
                &>*{
                    max-width: 100%;
                    margin-top: 35px;
                    @include break(md) {
                        max-width: calc(50% - 20px);
                        margin-right: 20px;
                    }
                    @include break(lg) {
                        max-width: calc(33% - 20px);
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &__switches{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                @include break(md) {
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
        &__footer{
            width: 100%;
            padding: 20px 30px;
            background: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            &__label{
                font-family: $font-secondary;
                font-size: 16px;
                font-weight: normal;
                color: $color-day-gray;
                span{
                    font-weight: 600;
                }
            }
        }
    }
</style>
